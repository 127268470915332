<template>
  <v-container fluid>
    <v-layout row wrap v-if="!isLoading">
      <v-flex xs12 class="text-center mx-sm-8" mt-5>
        <h1>{{ $t(tutorialData.title) }}</h1>
        <template v-if="this.tutorialData.video">
          <div v-if="getVideoMaterial()">
            <h2 class="mt-10 mb-7">
              {{ $t("attestation.video-materials") }} LV
            </h2>
            <div class="video-container">
              <iframe
                :src="getVideoMaterial()"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              >
              </iframe>
            </div>
          </div>
          <div v-if="getRussianVideoMaterial()">
            <h2 class="mt-10 mb-7">
              {{ $t("attestation.video-materials") }} RUS
            </h2>
            <div class="video-container">
              <iframe
                :src="getRussianVideoMaterial()"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              >
              </iframe>
            </div>
          </div>
        </template>
        <template v-if="tutorialData.presentations">
          <h2 class="mt-10 mb-7">
            {{ $t("attestation.presentation-materials") }}
          </h2>
          <v-row
            v-for="(presentation, index) in tutorialData.presentations"
            :key="index"
          >
            <v-col
              cols="12"
              class="px-10"
              v-if="getPresentationMaterial(presentation)"
            >
              <custom-button
                :text="$t(presentation.title) || $t(tutorialData.title)"
                @click="openPresentation(presentation)"
                icon="open_in_new"
                block
              />
            </v-col>
          </v-row>
        </template>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { findIndex, includes, isEqual, isNil } from "lodash";
import { tutorials } from "../../helpers/constants";
import AttestationTest from "@/components/AttestationTest.vue";
import CustomButton from "@/components/CustomButton.vue";
export default {
  components: { AttestationTest, CustomButton },
  data() {
    return {
      tutorialId: this.$route.params.tutorialId,
      tutorialsList: tutorials,
      tutorialData: {},
      isLoading: true,
      isBfhUser: includes(this.$root.currentUserData.aiderServices, "bfh"),
    };
  },
  created() {
    this.getTutorialData();
  },
  methods: {
    isMobile() {
      return screen.width <= 760;
    },
    openPresentation(presentation) {
      if (this.isMobile() && presentation.iframeUrl) {
        this.$router.push({
          name: `presentationFrame`,
          params: {
            url: presentation.iframeUrl,
          },
        });
      } else {
        window.open(presentation.url, "_blank");
      }
    },
    getPresentationMaterial(presentation) {
      if (!isNil(presentation.service)) {
        if (isEqual(presentation.service, "bfh") && this.isBfhUser) {
          return presentation;
        } else if (!isEqual(presentation.service, "bfh") && !this.isBfhUser) {
          return presentation;
        }
      } else {
        return presentation;
      }
    },
    getVideoMaterial() {
      if (!isNil(this.tutorialData.video.bfh) && this.isBfhUser) {
        return this.tutorialData.video.bfh;
      } else if (!isNil(this.tutorialData.video.neighborhood)) {
        return this.tutorialData.video.neighborhood;
      } else if (isEqual(typeof this.tutorialData.video, "string")) {
        return this.tutorialData.video;
      } else {
        return null;
      }
    },
    getRussianVideoMaterial() {
      if (!this.isBfhUser && !isNil(this.tutorialData.video.neighborhoodRu)) {
        return this.tutorialData.video.neighborhoodRu;
      } else {
        return null;
      }
      // if (!isNil(this.tutorialData.video.bfh) && this.isBfhUser) {
      //   return this.tutorialData.video.bfh;
      // } else if (!isNil(this.tutorialData.video.neighborhood)) {
      //   return this.tutorialData.video.neighborhood;
      // } else if (isEqual(typeof this.tutorialData.video, "string")) {
      //   return this.tutorialData.video;
      // } else {
      //   return null;
      // }
    },
    getTutorialData() {
      const indexOfTutorial = findIndex(this.tutorialsList, (tutorial) =>
        isEqual(tutorial.id, this.tutorialId)
      );
      if (indexOfTutorial > -1) {
        this.tutorialData = this.tutorialsList[indexOfTutorial];
      }
      this.isLoading = false;
    },
  },
};
</script>


<style scoped>
.v-btn ::v-deep .v-btn__content {
  white-space: normal;
  flex: auto;
}

.v-btn {
  height: 100% !important;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
